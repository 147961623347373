<template>
  <div class="content-wrapper text-left">
    <div class="lists-container d-flex px-2 pr-5">
      <draggable
        class="list-group"
        tag="div"
        v-model="lists"
        v-bind="dragOptions"
        @start="isDragging = true"
        @end="isDragging = false"
        handle=".handle"
      >
        <transition-group type="transition" name="flip-list" class="d-flex transition">
          <div class="list-group-item m-1 p-0" v-for="list of lists" :key="list.id">
            <BoardList :list="list"></BoardList>
          </div>
        </transition-group>
      </draggable>
      <div class="ml-2 p-2" ></div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import BoardList from "@/components/boards/BoardList";
import { mapGetters } from "vuex";
export default {
  components: {
    draggable,
    BoardList
  },
  computed: {
    ...mapGetters([]),
    lists: {
      get() {
        return this.$store.state.Boards.currentBoard.categories;
      },
      set(value) {
        this.$store.commit("updateBoardLists", value);
      }
    },
    dragOptions() {
      return {
        animation: 0,
        group: "lists",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  created() {
     this.$store.dispatch("getBoardData", this.$route.params.id);
  }
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  background: rgba(80, 75, 75, 0.144) (117, 104, 104);
  width: 100vw;
  // overflow-x: scroll;
}
.lists-container {
  max-height: 100vh;
  width: 700px;
  width: 100%;
  white-space: nowrap;
}
// dargdrop
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  // min-height: 20px;
  height: 130px;
  .transition {
    height: 130px;
  }
}
.list-group-item {
  width: 322px;
  background: transparent;
  border: none;

  -webkit-user-drag: none;
-khtml-user-drag: none;
-moz-user-drag: none;
-o-user-drag: none;
user-drag: none;

}

.extra-spacer {
  min-width: 339px;

  display: block;
}
</style>
