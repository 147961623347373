
import { Component, Prop, Vue, Ref } from "vue-property-decorator";

@Component
export default class CardTitle extends Vue {
  @Ref() readonly editTitle!: HTMLInputElement;
  @Prop(Object) public card!: { id: string; categoryId: string; title: string; taskNumber: string };
  onEditTitle = false;

  public disableEditTitle() {
    if (this.editTitle.value.length) {
      this.$store.commit("updateCardTitle", {
        cardId: this.card.id,
        listId: this.card.categoryId,
        title: this.editTitle.value
      });
    }
    this.onEditTitle = false;
  }
}
