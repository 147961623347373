
import { Component, Vue } from "vue-property-decorator";

@Component
export default class BoardAccount extends Vue {

  isShown = false;

  toggleDropdown() {
    this.isShown = !this.isShown;
  }
  onClose() {
    if (this.isShown) {
      this.isShown = !this.isShown;
    }
  }

  logOut() {
    this.$store.dispatch("logOut");
    this.$router.push("/");
    this.isShown=false;
  }
}
