
import { Component, Vue, Prop, Ref } from "vue-property-decorator";

@Component
export default class BoardAddCard extends Vue {
  @Ref() readonly inputCard!: HTMLInputElement;

  @Prop(String) private listId: string | undefined;

  isFormOpen = false;

  cardTitle = "";

  showCardForm() {
    this.isFormOpen = true;
    this.$nextTick(() => {
      this.inputCard.focus();
    });
  }
  autoGrow() {
    const element = this.inputCard;
    element.style.height = "5px";
    // element.style.paddingBottom = "40px";
    element.style.height = element.scrollHeight + "px";
  }
  submitForm() {
    if (this.cardTitle.length) {
      this.$store.dispatch("addBoardCard", {
        listId: this.listId,
        groupId: this.$route.params.id,
        cardTitle: this.cardTitle
      });
      this.cardTitle = "";
    }
    this.isFormOpen = false;
  }
}
