
import { Component, Vue, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import MenubarAbout from "@/components/boards/menubar/MenubarAbout.vue";
import MenubarBackground from "@/components/boards/menubar/MenubarBackground.vue";
import MenubarSearchCard from "@/components/boards/menubar/MenubarSearchCard.vue";
import MenubarStickers from "@/components/boards/menubar/MenubarStickers.vue";
import BoardActivity from "@/components/boards/menubar/BoardActivity.vue";
import BackgroundColors from "@/components/boards/menubar/background/BackgroundColors.vue";
import BackgroundImage from "@/components/boards/menubar/background/BackgroundImage.vue";

@Component({
  computed: mapGetters([
    "sidebarStatus",
    "itemsList",
    "menubarTitle",
    "componentName",
    "showItemContent"
  ]),
  components: {
    BoardActivity,
    MenubarAbout,
    MenubarBackground,
    MenubarSearchCard,
    MenubarStickers,
    BackgroundColors,
    BackgroundImage
  }
})
export default class BoardMenubar extends Vue {
  @Prop(Array) private activity: Array<object> | undefined;

  toggleMenu() {
    this.$store.dispatch("toggleMenuSidebar");
  }
}
