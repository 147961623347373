
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import axios from "axios";

import Editor from '@tinymce/tinymce-vue';


@Component({
  components: {
    Editor
  }
})
export default class CardActivity extends Vue {
  @Ref() readonly editDesc!: HTMLInputElement;
  @Prop(Object) public card!: { id: string; categoryId: string; description: string };
  comment = "";
  status = 0;
  comments: any = [];
  isShowEdit = false;
  created() {
    this.isShowEdit = true;
    this.loadData();

  }

  loadData() {
    try {
      const baseURI = 'api/GetComments/' + this.card.id;
      axios.get(baseURI)
        .then((response) => {
          this.comments = response.data;
          console.log(this.comments);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error)
    // eslint-disable-next-line no-empty
    {
    }
  }
  submit() {
    if (!this.comment) {
      return;
    }
    this.status = 1;
    const comments = new FormData();
    comments.append("comment", this.comment);
    comments.append("taskId", this.card.id);

    try {
      const baseURI = 'api/SaveComment';
      axios.post(baseURI, comments)
        .then((response) => {

          this.status = 0;
          this.comment = "";
          this.loadData();
        })
        .catch(function (error) {
          console.log(error);
        });
      // eslint-disable-next-line no-empty
    } catch (error) {

    }
  }
}
