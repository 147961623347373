
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: mapGetters(["sidebarStatus"])
})
export default class BoardMenu extends Vue {
  toggleSidebar() {
    console.log("toggle menu...");
    this.$store.dispatch("toggleMenuSidebar");
  }
}
