var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/icons/card-desc.svg")}}),_c('h5',{staticClass:"m-0"},[_vm._v("Description")]),(_vm.card.description && !_vm.isShowEdit)?_c('button',{staticClass:"edit ml-3 px-4 py-2",on:{"click":_vm.enableEdit}},[_c('img',{staticClass:"edit-icon",attrs:{"src":require("@/assets/images/edit-icon.png"),"alt":"edit","width":"12px;"}})]):_vm._e()]),(_vm.card.description && !_vm.isShowEdit)?_c('div',{staticClass:"card-desc p-2 pl-4",on:{"click":_vm.enableEdit}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.card.description)}})]):_vm._e(),(!_vm.card.description && !_vm.isShowEdit)?_c('div',{staticClass:"card-desc-empty"},[_c('div',{staticClass:"desc-btn ml-4 mt-3",on:{"click":_vm.enableEdit}},[_c('a',[_vm._v("Add more detailed information")])])]):_vm._e(),(_vm.isShowEdit)?_c('div',{staticClass:"card-desc-input ml-4 mt-3"},[_c('editor',{ref:"editDesc",attrs:{"api-key":"3flhb2vopzqjsajdg8ry598ng7pdjps5lgu9lzw7tm9yqdsr","init":{
       height: 500,
       menubar: false,
       plugins: [
         'advlist autolink lists link image charmap print preview anchor',
         'searchreplace visualblocks code fullscreen',
         'insertdatetime media table paste code help wordcount'
       ],
       toolbar:
         'undo redo | formatselect | bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat | help'
     }},model:{value:(_vm.card.description),callback:function ($$v) {_vm.$set(_vm.card, "description", $$v)},expression:"card.description"}}),_c('footer',{staticClass:"mt-2 d-flex align-items-center"},[_c('button',{staticClass:"btn btn-success px-4 py-2",on:{"click":_vm.hideEdit}},[_vm._v("Save")]),_c('span',{staticClass:"p-2 ml-1",on:{"click":function($event){_vm.isShowEdit=false}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('line',{attrs:{"x1":"18","y1":"6","x2":"6","y2":"18"}}),_c('line',{attrs:{"x1":"6","y1":"6","x2":"18","y2":"18"}})])])])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }