
import { Component, Vue } from "vue-property-decorator";
import NavbarTop from "@/components/NavbarTop.vue";
import { mapGetters } from "vuex";
import axios from "axios";
@Component({
  data() {
    return {
      URL: axios.defaults.baseURL
    }
  },
  computed: {
    ...mapGetters(["boardBackground"])
  },
  components: {
    NavbarTop
  }
})
export default class ComponentName extends Vue {
  [x: string]: any;
}
