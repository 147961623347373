
import { Component, Prop, Vue } from "vue-property-decorator";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import axios from "axios";


@Component({
  components: {
    vueDropzone: vue2Dropzone
  }
})
export default class CardAttachment extends Vue {
  @Prop(Object) public card!: { id: string; categoryId: string; description: string };

  dropzoneOptions: any = null;

  documents: any[] = [];
  

  created() {
    this.dropzoneOptions = {
      url: axios.defaults.baseURL + '/api/SaveDocuments/' + this.card.id,
      autoProcessQueue: true,
      paramName: "File upload",
      maxFilesize: 3, //mb
      maxThumbnailFilesize: 1, //mb
      maxFiles: 1,
      parallelUploads: 1,
      success: (file: any,response: any)=>{
       file.previewElement.remove(); 
       this.loadData();
      }
    };

    this.loadData();
  }

  loadData() {
    const baseURI = 'api/GetDocuments/' + this.card.id;
    axios.get(baseURI)
      .then((result) => {
        console.log(result.data);
        this.documents = result.data;
      })
  }

}
