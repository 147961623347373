import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios';
import Router from 'vue-router'
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "./directives/clickeOutside"; // custom Directive for detecting clicked outside

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { use } from "vue/types/umd";
Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

axios.defaults.baseURL = "https://localhost:7115";
axios.defaults.baseURL = "https://admin.nextask.uk";

axios.interceptors.request.use(
  (config) => {
    const token = store.getters.getCurrentUser.token;
    config.headers["Cache-Control"]='no-cache';
    if (token) {
     config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      //config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
   
    if (originalConfig.url !== "/" && err.response) {
      // Access Token was expired
      if (err.response.status === 401) {

           router.push("/");
  
      }
    }

    return Promise.reject(err);
  }
);

const user=localStorage.getItem("user");

if(user){
  const userObj=JSON.parse(user);
  if(user){
    store.dispatch('LogIn',userObj);
  }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
