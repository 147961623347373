
import { Component, Vue, Prop } from "vue-property-decorator";
import BoardVisibility from "@/components/boards/buttons/BoardVisibility.vue";
// import BoardPartOf from "@/components/boards/buttons/BoardPartOf.vue";
// import BoardStared from "@/components/boards/buttons/BoardStared.vue";
// import BoardInvite from "@/components/boards/buttons/BoardInvite.vue";
import BoardMenu from "@/components/boards/buttons/BoardMenu.vue";
@Component({
  components: {
    BoardVisibility,
    // BoardPartOf,
    // BoardStared,
    // BoardInvite,
    BoardMenu
  }
})
export default class BoardHeader extends Vue {
  @Prop(Object) private board: object | undefined;
}
