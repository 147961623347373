/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";


interface User {
  name: string;
  username: string;
  token: string;
  userTypeId: number;
}

@Module
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class UserModule extends VuexModule {
  currentUser: User = {
    name: "",
    username: "",
    token: "",
    userTypeId: -1
  };

  get isAuthenticated(): boolean {
    return this.currentUser.token ? true : false;
  }
  get getCurrentUser(): User {
    return this.currentUser;
  }

  get isAdmin(): boolean {
    return this.currentUser && this.currentUser.userTypeId == 0;
  }

  @Mutation
  public setUser(user: User) {
    this.currentUser = user;
    localStorage.setItem("user", JSON.stringify(user));
  }

  @Mutation
  public setLogOut(user: User) {
    //debugger;
    this.currentUser = user;
    localStorage.removeItem("user");
  }

  @Action
  public async LogIn(user: User) {

    this.context.commit("setUser", user);

  }

  @Action
  public logOut() {
    this.context.commit("setLogOut", {
      name: "",
      username: "",
      token: ""
    });
  }
}
