
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import Editor from '@tinymce/tinymce-vue';


@Component({
  components: {
    Editor
  }
})
export default class CardDesc extends Vue {
  @Ref() readonly editDesc!: HTMLInputElement;

  @Prop(Object) public card!: { id: string; categoryId: string; description: string };

  isShowEdit = false;

  public enableEdit(): void {
    this.isShowEdit = true;
    this.$nextTick(() => {
      //this.editDesc.focus();
    });
  }
  public hideEdit(): void {
    // this.desc = this.editDesc.value;0
    this.$store.commit("updateCardDesc", {
      cardId: this.card.id,
      listId: this.card.categoryId,
      desc: this.editDesc.value
    });
    this.isShowEdit = false;
  }
}
