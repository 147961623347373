
import { Component, Vue } from "vue-property-decorator";

@Component
export default class MenubarBackground extends Vue {
  colors = {
    icon: "",
    title: "Colors",
    contentOf: "BackgroundColors"
  };
  public showBackground(title: string, contentOf: string) {
    this.$store.dispatch("setMenubarContent");

    setTimeout(() => {
      this.$store.dispatch("setMenubarContent", {
        icon: "",
        title: title,
        contentOf: contentOf
      });
      this.$store.dispatch("setPreviousContent", {
        title: "change background",
        contentOf: "MenubarBackground"
      });
    }, 1);
  }
}
