

import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import axios from "axios";

@Component({
  components: {
    BoardCard: () => import('@/components/boards/BoardCard.vue')
  }
})

export default class CheckList extends Vue {
  @Prop(Object) card: any;
  @Prop(String) listTitle!: string;
  @Prop(Number) level!: number;


  onCheckChanged(event: Event, item: any) {
    console.log(event);
    axios.post('api/UpdateCheckStatus', item)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  addCheckItem() {
    this.$store.dispatch("addSubTask", {
      parentTaskId: this.card.id,
      cardTitle: ""
    });
  }
  createGuid() {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
  }


}
