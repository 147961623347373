
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["popupsValue"])
  }
})
export default class BoardVisibility extends Vue {
  selected: object = {
    label: "Public",
    icon: "globe"
  };
  visibility: Array<object> = [
    {
      label: "Private",
      desc: "Only board members can see and edit this board.",
      icon: "lock"
    },
    {
      label: "Team",
      desc:
        "All members of the team can see and edit this board. The board must be added to a team to enable this.",
      icon: "users"
    },
    {
      label: "Public",
      desc:
        "Anyone on the internet (including Google) can see this board. Only board members can edit.",
      icon: "globe"
    }
  ];
  isShown = false;

  toggleDropdown() {
    this.isShown = !this.isShown;
  }
  onClose() {
    if (this.isShown) {
      this.isShown = !this.isShown;
    }
  }
  selectVisibilityType(visibility: object) {
    this.selected = visibility;
    this.toggleDropdown();
  }
}
