import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import axios from 'axios'


interface Project {
  name: string;
}

interface Group {
  name: string;
}

interface Board {
  id: string;
  name: string;
  projectId: string;
  project: Project;
  categories: BoardList[];
}
interface BoardList {
  id: string;
  name: string;
  groupId: string;
  statusId: number;
  tasks: BoardCard[];
}
interface BoardCard {
  categoryId: string;
  priority: number;
  title: string;
  description: string;
  action: string;
  assignedDate: Date;
  estimatedCompletionDate: Date;
  dateCompleted: Date;
  billable: boolean;
  estimatedCost: number;
  displayOrder: number;
  status: number;
  createdOn: Date;
  createdById: string;
  lastUpdatedOn: Date;
  lastUpdatedById: string;
  id: string;
  taskForId: string;
  subTasks: BoardCard[];
}

@Module
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class Boards extends VuexModule {
  currentBoard: Board = {
    id: "",
    name: "",
    projectId: "",
    project:
      { name: '' },
    categories: []
  };

  projects: Project[] = [];


  get getCurrentBoard(): Board {
    return this.currentBoard;
  }

  get getCurrentProjects(): Project[] {
    return this.projects;
  }

  @Mutation
  public setProject(projects: Project[]) {

    this.projects = projects;
  }

  @Mutation
  public setBoardData(boardData: Board) {
    this.currentBoard = boardData;
    for (let i = 0; i < this.currentBoard.categories.length; i++) {
      for (let j = 0; j < this.currentBoard.categories[i].tasks.length; j++) {
        const task = this.currentBoard.categories[i].tasks[j];
        if (task.assignedDate) {
          task.assignedDate = new Date(task.assignedDate);
        }
        for (let k = 0; k < task.subTasks.length; k++) {
          const subtask = task.subTasks[k];
          subtask.assignedDate = new Date(subtask.assignedDate);
        }
      }
    }
  }

  @Mutation
  public updateBoardLists(lists: BoardList[]) {
    this.currentBoard.categories = lists;
  }

  @Mutation
  public updateBoardCards(payload: { cards: BoardCard[]; listId: string; global: string }) {
    const foundList = this.currentBoard.categories.find(
      list => list.id == payload.listId
    );

    if (foundList) {

      for (let i = 0; i < payload.cards.length; i++) {
        payload.cards[i].categoryId = payload.listId;
      }

      console.log(payload);

      foundList.tasks = payload.cards;

      axios.post('api/savetaskList?global=' + payload.global, payload.cards)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }


  }
  @Mutation insertBoardCard(card: BoardCard) {

    const foundList = this.currentBoard.categories.find(
      list => list.id == card.categoryId
    );
    if (foundList) {
      card.displayOrder = 1;
      if (foundList.tasks.length > 0) {
        card.displayOrder = foundList.tasks[foundList.tasks.length - 1].displayOrder + 1;
      }
      foundList.tasks.push(card);
    }
  }
  @Mutation
  public insertBoardList(list: BoardList) {
    this.currentBoard.categories.push(list);
  }


  @Mutation
  public updateCardDesc(payload: {
    cardId: string;
    listId: string;
    desc: string;
  }) {

    const foundList = this.currentBoard.categories.find(
      list => list.id == payload.listId
    );
    if (foundList) {
      const foundCard = foundList.tasks.find(card => card.id == payload.cardId);
      foundCard ? (foundCard.description = payload.desc) : "";


      axios.post('api/savetask', foundCard)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  @Mutation
  public updateCardTitle(payload: {
    cardId: string;
    listId: string;
    title: string;
  }) {

    for (let i = 0; i < this.currentBoard.categories.length; i++) {
      let foundCard = this.currentBoard.categories[i].tasks.find(card => card.id == payload.cardId);

      if (!foundCard) {

        for (let j = 0; j < this.currentBoard.categories[i].tasks.length; j++) {
          foundCard = this.currentBoard.categories[i].tasks[j].subTasks.find(card => card.id == payload.cardId);
          if (foundCard) {
            break;
          }
        }
      }

      if (foundCard) {
        foundCard ? (foundCard.title = payload.title) : "";
        axios.post('api/savetask', foundCard)
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      }

    }

  }

  @Action
  public async getBoardData(id: string) {
    const baseURI = 'api/getgroup/' + id;
    axios.get(baseURI)
      .then((result) => {
        console.log(result.data);
        this.context.commit("setBoardData", result.data as Board);
        console.log(this.currentBoard);
      })
  }

  @Action
  public async getProjects() {
    const baseURI = 'api/getprojects';
    axios.get(baseURI)
      .then((result) => {
        console.log(result.data);
        this.context.commit("setProject", result.data);
        console.log(this.projects);
      })
  }


  @Action
  public addBoardCard(payload: { groupId: string; listId: string; cardTitle: string }) {

    function createGuid() {
      function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }
      return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
    }

    const card = {
      id: createGuid(),
      groupId: payload.groupId,
      categoryId: payload.listId,
      title: payload.cardTitle,
      description: "",
      priority: 3,
      subTasks: []
    };

    this.context.commit("insertBoardCard", card);

    axios.post('api/savetask', card)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  @Action
  public addSubTask(payload: { parentTaskId: string; cardTitle: string }) {


    function createGuid() {
      function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }
      return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
    }

    const card = {
      id: createGuid(),
      parentTaskId: payload.parentTaskId,
      title: payload.cardTitle,
      description: "",
      subTasks: []
    };

    for (let i = 0; i < this.currentBoard.categories.length; i++) {
      const foundCard = this.currentBoard.categories[i].tasks.find(card => card.id == payload.parentTaskId);
      if (foundCard) {
        const subTask = (card as unknown) as BoardCard;

        subTask.displayOrder = 1;
        if (foundCard.subTasks.length > 0) {
          subTask.displayOrder = foundCard.subTasks[foundCard.subTasks.length - 1].displayOrder + 1;
        }

        foundCard.subTasks.push(subTask);
      }
    }
    if (!payload.cardTitle!) {
      return;
    }
    axios.post('api/savetask', card)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  @Action
  public addBoardList(listTitle: string) {
    const newList = {
      id: Date.parse(new Date().toString()) / 1000,
      boardId: this.currentBoard.id,
      title: listTitle,
      cards: []
    };
    this.context.commit("insertBoardList", newList);
  }

}
