

import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import CardMembers from "@/components/boards/cardModal/CardMembers.vue";
import CardDesc from "@/components/boards/cardModal/CardDesc.vue";
import CardActivity from "@/components/boards/cardModal/CardActivity.vue";
import CheckList from "@/components/boards/CheckList.vue";
import CardAttachment from "@/components/boards/cardModal/CardAttachment.vue";
import axios from "axios";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

@Component({
  components: {
    CardMembers,
    CardDesc,
    CardActivity,
    CheckList,
    CardAttachment,
    DatePicker
  }
})
export default class BoardCardModalContent extends Vue {
  @Prop(Object) card: any;
  users: any = [];
  created() {
    this.loadData();
  }

  loadData() {
    try {
      const baseURI = 'api/GetUsers/' + this.card.groupId;
      axios.get(baseURI)
        .then((response) => {
          this.users = response.data;
          console.log(this.users);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error)
    // eslint-disable-next-line no-empty
    {
    }
  }

  @Watch('card', { deep: true })
  onCardhanged(value: any, oldValue: any) {

    axios.post('api/updateFulllTask', this.card)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

}
