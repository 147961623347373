<template>
  <div class="home d-flex flex-column">
    <div v-for="item in getCurrentProjects" :key="item.id">
      {{ item.name }}
      <ul class="project-list">
        <li v-for="grp in item.groups" :key="grp.id">
          <router-link :to="{ name: 'Home', params: { id: grp.id }}">{{grp.name}}</router-link>
        </li>
  
      </ul>
    </div>
    <router-link v-if="isAdmin" :to="{ name: 'Home', params: { id: '00000000-0000-0000-0000-000000000000'}}">global</router-link>

  </div>
</template>

<script>

import { mapGetters } from "vuex";
export default {
  name: "Home",
  components: {
  },
  computed: {

    ...mapGetters(["getCurrentProjects","isAdmin"])
  },
  created() {
    this.$store.dispatch("getProjects");
  }
};
</script>
<style lang="scss" scoped>
.home {
  // navbar height is 50px
  height: calc(100vh - 53px);
  overflow: hidden;

  .main-board {
    overflow-y: hidden;
    overflow-x: auto;
    height: 100%;
  }
}

.project-list{
  // outline: 1px solid wheat;
  li{
    list-style-type: none
  }
}

.grab-bing {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: grab;
}

.grab-bing:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: grabbing;
}
</style>