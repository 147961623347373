<template>
  <div class="login">
    <div>
      <form @submit.prevent="submit">
        <div>
          <label for="username">Username:</label>
          <input type="text" name="username" required="" v-model="form.username" />
        </div>
        <div>
          <label for="password">Password:</label>
          <input type="password" name="password" required="" v-model="form.password" />
        </div>
        <button type="submit">Submit</button>
      </form>
      <p v-if="showError" id="error">Username or Password is incorrect</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import store from "../store";
import axios from "axios";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      showError: false
    };
  },
  computed: {
    ...mapGetters({ getCurrentUser: 'getCurrentUser' })
  },
  methods: {
    ...mapActions(["LogIn"]),
    async submit() {
      const User = new FormData();
      User.append("username", this.form.username);
      User.append("password", this.form.password);
      try {

        const baseURI = 'api/login';

        axios.post(baseURI, User)
          .then((response) => {
            if (response.data.token) {
              this.LogIn(response.data);
              this.showError = false;
              this.$router.push("/Projects");
            }
            else {
              this.showError = true;
            }
          })
          .catch(function (error) {
            console.log(error);
            this.showError = true;   
          
          });


      } catch (error) {
        this.showError = true
      }
    },
  },
};
</script>

<style lang="scss" scoped >
* {
  box-sizing: border-box;
}

.login{
  height: 100vh;
  display: flex;
    align-items: flex-start;
    justify-content: center;
    & > div{  
      padding-top: 15vh;        
    }
}




label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

button[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 5px;
}

button[type="submit"]:hover {
  background-color: #45a049;
}

input {
  margin: 5px;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
  padding: 10px;
  border-radius: 5px;
}

#error {
  color: red;
}</style>